let formatFn = {
	timeFormat: timeFormat,
	spermissionTreeFormat: spermissionTreeFormat,
}

function timeFormat() {
	const time = new Date()
	const hour = time.getHours()
	return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}
// 处理组织树
function spermissionTreeFormat(intData) {
	const treeData = intData.map(item => {
		if (item.children) {
			return {
				...item,
				label: item.displayName,
				value: item.resourceCode,
				children: spermissionTreeFormat(item.children)
			}
		} else {
			return {
				...item,
				label: item.displayName,
				value: item.resourceCode,
			}
		}
	})
	return treeData
}
export default formatFn
