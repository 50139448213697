<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="role">
		<div class="role-content">
			<div class="role-search">
				<div class="common-row">
					<div class="common-row-item">
						<el-button @click="actionFn('addBm')">新增部门</el-button>
						<el-button @click="actionFn('addBmp')">新增部门成员</el-button>
						<el-button @click="actionFn('addFh')">新增分会</el-button>
						<el-button @click="actionFn('addFhp')">分会权限管理</el-button>
					</div>
				</div>
			</div>
			<el-tabs v-model="currentTab" @tab-click="changeTab">
				<el-tab-pane label="部门" name="0"></el-tab-pane>
				<el-tab-pane label="部门成员" name="1"></el-tab-pane>
				<el-tab-pane label="分会" name="2"></el-tab-pane>
			</el-tabs>
			<div class="role-table">
				<el-table :data="tableData" border>
					<el-table-column type="index" label="序号" width="50px">
					</el-table-column>
					<el-table-column :prop="currentTab == 0?'orgName':currentTab == 1?'name':'name'"
						:label="currentTab == 0?'所属组织':currentTab == 1?'角色名称':'所属组织'">
					</el-table-column>
					<el-table-column :prop="currentTab == 0?'name':currentTab == 1?'orgName':'roleName'"
						:label="currentTab == 0?'部门名称':currentTab == 1?'所属部门':'所属角色'">
					</el-table-column>
					<el-table-column prop="updateTime" label="修改时间"></el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('edit', scope)">{{currentTab == 2?'编辑':'权限编辑'}}</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="actionFn('delete', scope)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog @close="closeDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
			:visible.sync="dialogVisible" width="520px">
			<div slot="title" class="dialog-header">
				<span>{{dialogTitle}}</span>
				<img @click="closeDialog" src="@/assets/images/common/close.png">
			</div>
			<el-form :model="roleForm" :rules="roleRules" ref="roleForm" label-width="100px" class="">
				<!-- 新增部门 -->
				<div class="" v-if="dialogType == 'addBm' || dialogType == 'editBm'">
					<el-form-item class="roleForm-item" label="部门名称:" prop="bmName">
						<el-input class="roleForm-item-ipt" v-model="roleForm.bmName" placeholder="请输入" />
					</el-form-item>
					<el-form-item class="roleForm-item" label="部门权限:" prop="bmPerm">
						<el-tree @check="getCheckedBmNodes" :data="treeData" show-checkbox node-key="id"
							:default-checked-keys="roleForm.bmPerm">
						</el-tree>
					</el-form-item>
				</div>
				<!-- 新增部门成员 -->
				<div class="" v-if="dialogType == 'addBmp' || dialogType == 'editBmp'">
					<el-form-item v-if="dialogType == 'addBmp'" class="roleForm-item" label="所在部门:" prop="belongBm">
						<el-select @change="bmChange" class="roleForm-item-ipt" v-model="roleForm.belongBm"
							placeholder="请选择">
							<el-option v-for="item in bmList" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="roleForm-item" label="角色名称:" prop="roleName">
						<el-input class="roleForm-item-ipt" v-model="roleForm.roleName" placeholder="请输入" />
					</el-form-item>
					<el-form-item class="roleForm-item" label="角色权限:" prop="bmpPerm">
						<el-tree @check="getCheckedBmpNodes" :data="treeData" show-checkbox node-key="id"
							:default-checked-keys="roleForm.bmpPerm">
						</el-tree>
					</el-form-item>
				</div>
				<div class="" v-if="dialogType == 'addFh' || dialogType == 'editFh'">
					<el-form-item class="roleForm-item" label="分会名称:" prop="fhName">
						<el-input class="roleForm-item-ipt" v-model="roleForm.fhName" placeholder="请输入" />
					</el-form-item>
				</div>
				<div class="" v-if="dialogType == 'addFhp'">
					<el-form-item class="roleForm-item" label="角色权限:" prop="fhpPerm">
						<el-tree @check="getCheckedFhpNodes" :data="treeData" show-checkbox node-key="id"
							:default-checked-keys="roleForm.fhpPerm">
						</el-tree>
					</el-form-item>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button v-preventReClick @click="submitDialog" type="primary">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getRoleList,
		getDepartmentList,
		getPermissionTree,
		addOrigin,
		detailOrigin,
		editOrigin,
		addbmMemeber,
		detailbmMemeber,
		editbmMemeber,
		listFhRoles,
		deleteRoles,
		deleteBmMember,
	} from "@/api/system";
	import formatFn from "@/utils/formatFn.js";
	export default {
		components: {},
		data() {
			const checkfhpPerm = (rule, value, callback) => {
				if (this.fhpPermChecked.length == 0) {
					callback(new Error("请选择角色权限"));
				} else {
					callback();
				}
				callback();
			};
			const checkbmpPerm = (rule, value, callback) => {
				if (this.bmpPermChecked.length == 0) {
					callback(new Error("请选择角色权限"));
				} else {
					callback();
				}
				callback();
			};
			const checkbmPerm = (rule, value, callback) => {
				if (this.bmPermChecked.length == 0) {
					callback(new Error("请选择角色权限"));
				} else {
					callback();
				}
				callback();
			};
			return {
				tableData: [],
				searchForm: {},
				dialogVisible: false,
				dialogTitle: "",
				dialogType: "",
				roleForm: {},
				roleRules: {
					bmName: [{
						required: true,
						message: "请输入部门名称",
						trigger: "blur"
					}],
					bmPerm: [{
						required: true,
						validator: checkbmPerm,
						trigger: "blur"
					}],
					belongBm: [{
						required: true,
						message: "请选择所在部门",
						trigger: "blur"
					}],
					roleName: [{
						required: true,
						message: "请输入角色名称",
						trigger: "blur"
					}],
					bmpPerm: [{
						required: true,
						validator: checkbmpPerm,
						trigger: "blur"
					}],
					fhpPerm: [{
						required: true,
						validator: checkfhpPerm,
						trigger: "blur"
					}],
					fhName: [{
						required: true,
						message: "请输入角名称",
						trigger: "blur"
					}],
				},
				treeData: [],
				currentTab: "0",
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				bmList: [],
				fhpPermChecked: [],
				bmpPermChecked: [],
				bmPermChecked: [],
			};
		},
		watch: {
			dialogVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.roleForm = {}
							this.$refs["roleForm"].clearValidate()
						})
					}
				}
			},
			deep: true
		},
		mounted() {
			this.departmentList();
		},
		methods: {
			changeTab(e) {
				console.log("changeTab", e)
				this.currentTab = e.name
				this.pageIndex = 1;
				if (this.currentTab == 1) {
					this.roleList();
				} else {
					this.departmentList();
				}
			},
			async actionFn(type, scope) {
				switch (type) {
					case "addBm":
						this.dialogType = "addBm"
						this.dialogTitle = "新增部门"
						this.roleForm = {}
						this.permissionTree()
						this.dialogVisible = true
						break;
					case "addBmp":
						this.dialogType = "addBmp"
						this.dialogTitle = "新增部门成员"
						this.roleForm = {}
						this.bmList = [];
						this.treeData = []
						this.dialogVisible = true
						// await this.bmPermissionTree()
						getDepartmentList({
							pageIndex: 1,
							pageSize: 999,
							type: "DEPT"
						}).then((res) => {
							if (res.code == 0) {
								this.bmList = res.data.map(item => {
									return {
										label: item.name,
										value: item.id
									}
								})
							}
						})
						break;
					case "addFh":
						this.dialogType = "addFh"
						this.dialogTitle = "新增分会"
						this.roleForm = {}
						this.dialogVisible = true
						break;
					case "addFhp":
						await this.permissionTree()
						listFhRoles().then(res => {
							if (res.code == 0) {
								if (res.data.id) {
									this.$set(this.roleForm, "id", res.data.id)
									let filterArr = res.data.permissionList.filter(item => item.apiSet == 1)
									let roleIds = filterArr.map(item => {
										return item.id
									})
									// console.log("roleIds", roleIds)
									this.fhpPermChecked = roleIds
									this.$set(this.roleForm, "fhpPerm", roleIds)
								}
							}
						})
						this.dialogType = "addFhp"
						this.dialogTitle = "分会权限管理"
						this.dialogVisible = true
						break;
					case "edit":
						this.roleForm = {}
						if (this.currentTab == 1) {
							this.dialogType = "editBmp"
							this.dialogTitle = "编辑部门成员"
							await this.bmPermissionTree(scope.row.orgId)
							detailbmMemeber(scope.row.id, {
								id: scope.row.id
							}).then((res) => {
								// belongBm
								this.$set(this.roleForm, "id", res.data.id)
								this.$set(this.roleForm, "roleName", res.data.name)
								if (res.data.permissionList) {
									// let newArr = res.data.permissionList.map(item => {
									// 	return item.id
									// })
									let filterArr = res.data.permissionList.filter(item => item.apiSet == 1)
									let newArr = filterArr.map(item => {
										return item.id
									})
									this.bmpPermChecked = newArr
									this.$set(this.roleForm, "bmpPerm", newArr)
								}
								this.$forceUpdate()
							})
						} else {
							if (this.currentTab == 0) {
								this.dialogType = "editBm"
								this.dialogTitle = "编辑部门"
								await this.permissionTree()
							} else if (this.currentTab == 2) {
								this.dialogType = "editFh"
								this.dialogTitle = "编辑分会"
							}
							detailOrigin(scope.row.id, {
								id: scope.row.id
							}).then((res) => {
								this.$set(this.roleForm, "id", res.data.id)
								if (this.currentTab == 0) {
									this.$set(this.roleForm, "bmName", res.data.name)
									if (res.data.permissionList) {
										let filterArr = res.data.permissionList.filter(item => item.apiSet ==
											1)
										let newArr = filterArr.map(item => {
											return item.id
										})
										this.bmPermChecked = newArr
										this.$set(this.roleForm, "bmPerm", newArr)
									}
								} else if (this.currentTab == 2) {
									this.$set(this.roleForm, "fhName", res.data.name)
								}
								this.$forceUpdate()
							})
							console.log("edit", this.roleForm)
						}
						this.dialogVisible = true
						break;
					case "delete":
						let confirmText = ""
						let confirmTitle = ""
						let messageText = ""
						let params = {}
						if (this.currentTab == 0) {
							confirmText = "确定删除此部门吗？"
							confirmTitle = "部门删除"
							params = {
								id: scope.row.id
							}
							messageText = "部门删除成功~"
						} else if (this.currentTab == 1) {
							confirmText = "确定删除此部门成员吗？"
							confirmTitle = "部门成员删除"
							params = {
								id: scope.row.id
							}
							messageText = "部门成员删除成功~"
						} else if (this.currentTab == 2) {
							confirmText = "确定删除此分会吗？"
							confirmTitle = "分会删除"
							params = {
								id: scope.row.id
							}
							messageText = "分会删除成功~"
						}
						this.$confirm(confirmText, confirmTitle, {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							if (this.currentTab == 0 || this.currentTab == 2) {
								deleteRoles(scope.row.id, params).then((res) => {
									if (res.code == 0) {
										this.$message({
											type: 'success',
											message: messageText
										});
										this.departmentList();
										this.$forceUpdate()
									}
								})
							} else {
								deleteBmMember(scope.row.id, params).then((res) => {
									if (res.code == 0) {
										this.$message({
											type: 'success',
											message: messageText
										});
										this.roleList();
										this.$forceUpdate()
									}
								})
								
							}
						}).catch(() => {});
						break;
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				if (this.currentTab == 1) {
					this.roleList();
				} else {
					this.departmentList();
				}
			},
			// 获取部门成员
			roleList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				this.tableData = [];
				getRoleList(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			// 获取部门/分会列表
			departmentList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				this.tableData = [];
				console.log(this.currentTab)
				if (this.currentTab == 0) {
					this.$set(params, "type", "DEPT")
				} else if (this.currentTab == 2) {
					this.$set(params, "type", "SUB_COMP")
				}
				getDepartmentList(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			bmChange(e) {
				console.log("bmChange", e)
				this.bmpPermChecked = []
				this.bmPermissionTree(e)
			},
			// 获取组织树
			permissionTree(type) {
				return new Promise((resolve, reject) => {
					getPermissionTree().then((res) => {
						if (res.code == 0) {
							if(type == 2) {
								resolve(res.data)
							} else {
								let treeData = res.data
								this.treeData = formatFn.spermissionTreeFormat(treeData)
								resolve()
							}
						} else {
							resolve([])
							reject()
						}
					})
				})
			},
			// 获取部门组织树
			bmPermissionTree(id) {
				return new Promise((resolve, reject) => {
					detailOrigin(id, {
						id: id
					}).then((res) => {
						if (res.code == 0) {
							let treeData = res.data.permissionVOList
							this.treeData = formatFn.spermissionTreeFormat(treeData)
							resolve()
						} else {
							resolve([])
						}
						this.$forceUpdate()
					})
				})
			},
			// 获取勾选权限
			getCheckedBmNodes(val1, val2) {
				console.log("getCheckedBmNodes1", val2)
				this.bmPermChecked = val2.checkedKeys.concat(val2.halfCheckedKeys)
				// this.$set(this.roleForm, "bmPerm", val2.checkedKeys.concat(val2.halfCheckedKeys))
			},
			getCheckedBmpNodes(val1, val2) {
				this.bmpPermChecked = val2.checkedKeys.concat(val2.halfCheckedKeys)
				// this.$set(this.roleForm, "bmpPerm", val2.checkedKeys.concat(val2.halfCheckedKeys))
			},
			getCheckedFhpNodes(val1, val2) {
				console.log("getCheckedFhpNodes", val1, val2)
				this.fhpPermChecked = val2.checkedKeys.concat(val2.halfCheckedKeys)
				// this.$set(this.roleForm, "fhpPerm", val2.checkedKeys.concat(val2.halfCheckedKeys))
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog() {
				this.$refs["roleForm"].validate(async valid => {
					if (valid) {
						let formData = JSON.parse(JSON.stringify(this.roleForm))
						console.log("formData", formData)
						let params = {}
						let message = ""
						let resourcesArr = []
						if (this.dialogType == "addFhp") {
							let perIds = []
							let treeDatas = await this.permissionTree(2)
							if(treeDatas.length > 0) {
								this.fhpPermChecked.forEach(item => {
									treeDatas.forEach(citem => {
										if(citem.children.some(sitem => sitem.id == item)) {
											perIds.push(citem.id)
										}
									})
								})
							}
							// perIds = [...new Set(perIds)]
							this.fhpPermChecked = [...new Set(this.fhpPermChecked.concat(perIds))]
							params = {
								orgId: 0,
								code: "",
								name: "分会角色",
								permissionIdList: this.fhpPermChecked,
							}
							// console.log("params", params)
							message = "分会权限管理成功~"
							if (formData.id) {
								editbmMemeber(formData.id, params).then((res) => {
									if (res.code == 0) {
										this.$message.success(message)
										this.pageIndex = 1;
										this.tableData = [];
										this.departmentList();
										this.dialogVisible = false
									}
								})
							} else {
								addbmMemeber(params).then((res) => {
									if (res.code == 0) {
										this.$message.success(message)
										this.pageIndex = 1;
										this.tableData = [];
										this.departmentList();
										this.dialogVisible = false
									}
								})
							}
						} else {
							if (this.dialogType == 'addBmp' || this.dialogType == 'editBmp') {
								let perIds = []
								let treeDatas = await this.permissionTree(2)
								if(treeDatas.length > 0) {
									this.bmpPermChecked.forEach(item => {
										treeDatas.forEach(citem => {
											if(citem.children.some(sitem => sitem.id == item)) {
												perIds.push(citem.id)
											}
										})
									})
								}
								// perIds = [...new Set(perIds)]
								this.bmpPermChecked = [...new Set(this.bmpPermChecked.concat(perIds))]
								params = {
									name: formData.roleName,
									permissionIdList: this.bmpPermChecked,
									code: "",
								}
								console.log("addBmp", params)
								if (this.dialogType == 'addBmp') {
									message = "新增部门成员成功~"
									this.$set(params, "orgId", formData.belongBm)
									addbmMemeber(params).then((res) => {
										if (res.code == 0) {
											this.$message.success(message)
											this.pageIndex = 1;
											this.tableData = [];
											if (this.currentTab == 1) {
												this.roleList();
											} else {
												this.departmentList();
											}
											this.dialogVisible = false
										}
									})
								} else {
									message = "编辑部门成员成功~"
									this.$set(params, "id", formData.id)
									editbmMemeber(formData.id, params).then((res) => {
										if (res.code == 0) {
											this.$message.success(message)
											this.pageIndex = 1;
											this.tableData = [];
											this.roleList();
											this.dialogVisible = false
										}
									})
								}

							} else {
								if (this.dialogType == 'addBm' || this.dialogType == 'editBm') {
									let perIds = []
									let treeDatas = await this.permissionTree(2)
									if(treeDatas.length > 0) {
										this.bmPermChecked.forEach(item => {
											treeDatas.forEach(citem => {
												if(citem.children.some(sitem => sitem.id == item)) {
													perIds.push(citem.id)
												}
											})
										})
									}
									// perIds = [...new Set(perIds)]
									this.bmPermChecked = [...new Set(this.bmPermChecked.concat(perIds))]
									if (this.bmPermChecked.length > 0) {
										this.bmPermChecked.forEach(item => {
											resourcesArr.push({
												resourceId: item
											})
										})
									}
									params = {
										name: formData.bmName,
										resources: resourcesArr,
										type: "DEPT"
									}
									// console.log("params", params)
									if (this.dialogType == 'addBm') {
										message = "部门新增成功~"
										addOrigin(params).then((res) => {
											if (res.code == 0) {
												this.$message.success(message)
												this.pageIndex = 1;
												this.tableData = [];
												if (this.currentTab == 1) {
													this.roleList();
												} else {
													this.departmentList();
												}
												this.dialogVisible = false
											}
										})
									} else if (this.dialogType == 'editBm') {
										message = "部门编辑成功~"
										this.$set(params, "id", formData.id)
										editOrigin(formData.id, params).then((res) => {
											if (res.code == 0) {
												this.$message.success(message)
												this.pageIndex = 1;
												this.tableData = [];
												if (this.currentTab == 1) {
													this.roleList();
												} else {
													this.departmentList();
												}
												this.dialogVisible = false
											}
										})
									}
								} else if (this.dialogType == 'addFh' || this.dialogType == 'editFh') {
									params = {
										name: formData.fhName,
										type: "SUB_COMP"
									}
									if (this.dialogType == 'addFh') {
										message = "分会新增成功~"
										addOrigin(params).then((res) => {
											if (res.code == 0) {
												this.$message.success(message)
												this.pageIndex = 1;
												this.tableData = [];
												if (this.currentTab == 1) {
													this.roleList();
												} else {
													this.departmentList();
												}
												this.dialogVisible = false
											}
										})
									} else if (this.dialogType == 'editFh') {
										message = "分会编辑成功~"
										this.$set(params, "id", formData.id)
										editOrigin(formData.id, params).then((res) => {
											if (res.code == 0) {
												this.$message.success(message)
												this.pageIndex = 1;
												this.tableData = [];
												if (this.currentTab == 1) {
													this.roleList();
												} else {
													this.departmentList();
												}
												this.dialogVisible = false
											}
										})
									}
								}
								console.log("submitDialog", params)

							}
						}
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #DCDFE6;
	}

	.role {
		.role-content {
			padding: 0 20px 20px 20px;

			.role-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.role-table {
				margin-top: 20px;
			}
		}

		.roleForm-item {
			margin-top: 20px;

			.roleForm-item-ipt {
				width: 350px;
			}
		}

	}
</style>
